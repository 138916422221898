<template>
  <div class="jobStatistics">
    <span class="ant-menu-item-selected title-label bt-3">需求招聘统计</span>
    <div
      :style="{
        'border-top': '1px solid #E0E1E2',
        'margin-top': '-1px',
        'min-height': clientH
      }"
    >
      <div
        id="myChart"
        :style="{ width: '90%', height: '300px', 'margin-top': '20px' }"
      ></div>
      <div
        id="myChart2"
        :style="{ width: '90%', height: '300px', 'margin-top': '20px' }"
      ></div>
    </div>
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name: 'jobStatistics',
  data () {
    return {
      clientH: '500px',
      fontColor: ''
    }
  },
  mounted () {
    this.fontColor = JSON.parse(
      window.localStorage.getItem('global_DEFAULT')
    ).primaryColor
    this.drawLine()
    this.drawLine2()
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 130 + 'px'
    console.log(this.clientH)
  },
  methods: {
    drawLine () {
      let that = this
      let myChart = echarts.init(document.getElementById('myChart'))
      // 指定图表的配置项和数据
      var myChart_option = {
        title: {
          text: '在Vue中使用echarts',
          left: 'center',
          textStyle: {
            fontSize: 14,
            color: that.fontColor
          }
        },
        color: ['#0fccf2'],
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        // dataZoom: [{
        //     startValue: '深圳'
        // }, {
        //     type: 'inside'
        // }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            align: 'left'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['深圳', '广州', '上海', '重庆'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              color: '#38d7ed'
            },
            axisLine: {
              lineStyle: {
                color: '#38d7ed'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: '#38d7ed'
            },
            axisLine: {
              lineStyle: {
                color: '#38d7ed'
              }
            },
            splitLine: {
              //分割线配置
              show: true,
              lineStyle: {
                color: '#38d7ed'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              normal: {
                // color: '#ffffff',
                label: {
                  show: true,
                  color: '#fff',
                  position: 'top',
                  formatter: '{c}'
                }
              }
            },
            data: [2, 1, 1, 1]
          }
        ]
      }
      myChart.setOption(myChart_option)
    },

    drawLine2 () {
      let that = this
      let myChart = echarts.init(document.getElementById('myChart2'))
      // 指定图表的配置项和数据
      var myChart_option = {
        title: {
          text: '在Vue中使用echarts',
          left: 'center',
          textStyle: {
            fontSize: 14,
            color: that.fontColor
          }
        },
        color: ['#0fccf2'],
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: {
              show: true,
              readOnly: false,
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data
                var series = opt.series
                var table =
                  '<table style="width:100%;text-indent:10px;border:1px solid red;"><tbody><tr style="border:1px solid red;">' +
                  '<td>城市</td>' +
                  '<td>数据</td>' +
                  // + '<td>' + series[1].name + '</td>'
                  '</tr>'
                for (var i = 0, l = axisData.length; i < l; i++) {
                  table +=
                    '<tr  style="border:1px solid red;">' +
                    '<td>' +
                    axisData[i] +
                    '</td>' +
                    '<td>' +
                    series[0].data[i] +
                    '</td>' +
                    // + '<td>' + series[1].data[i] + '</td>'
                    '</tr>'
                }
                table += '</tbody></table>'
                return table
              }
            },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
            // myTool1: {
            //     show: true,
            //     title: '自定义扩展方法1',
            //     icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
            //     onclick: function (){
            //         alert('myToolHandler1')
            //     }
            // },
            // myTool2: {
            //     show: true,
            //     title: '自定义扩展方法',
            //     icon: 'image://http://echarts.baidu.com/images/favicon.png',
            //     onclick: function (){
            //         alert('myToolHandler2')
            //     }
            // }
          }
        },
        dataZoom: [
          {
            startValue: '深圳'
          },
          {
            type: 'inside'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            align: 'left'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [
              '深圳',
              '广州',
              '上海',
              '重庆',
              '南京',
              '杭州',
              '北京',
              '沈阳',
              '哈尔冰',
              '长沙',
              '厦门',
              '泉州',
              '温州'
            ],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              color: '#38d7ed'
            },
            axisLine: {
              lineStyle: {
                color: '#38d7ed'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: '#38d7ed'
            },
            axisLine: {
              lineStyle: {
                color: '#38d7ed'
              }
            },
            splitLine: {
              //分割线配置
              show: true,
              lineStyle: {
                color: '#38d7ed'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              normal: {
                // color: '#ffffff',
                label: {
                  show: true,
                  color: '#fff',
                  position: 'top',
                  formatter: '{c}'
                }
              }
            },
            data: [7, 1, 6, 1, 8, 12, 3, 8, 12, 1, 5, 6, 8, 7]
          }
        ]
      }
      myChart.setOption(myChart_option)
    }
  }
}
</script>
<style lang="less" scoped>
.jobStatistics {
  position: relative;
  background: #fff;
  border-radius: 4px;
  overflow-y: auto;
  user-select: none;
  font-size: 14px;
  color: #666666;
  padding: 15px 20px;
  .title-label {
    line-height: 36px;
    height: 36px;
    display: inline-block;
  }
}
</style>
